:root {
  --black: #2b2b2b;

  --fg: var(--black);
  --bg: var(--white);

  --blue: #006ab5;
  --ocean: #2d6470;
  --green: #b5f33b;
  --pistachio: #e0ff97;
  --azure: #0b99ed;
  --aqua: #8bd0d7;

  --accent: var(--blue);
  --accent-fg: var(--white);

  --faq-color: var(--white);
}

[data-menu-toggle="open"] {
  --icon-color: var(--black);

  .hero--top & {
    --icon-color: var(--white);
  }
}

.button--accent {
  &:hover,
  &:focus-visible {
    color: var(--white);
  }
}

.slogan span {
  --fg: var(--white);
}

body:not(.hero--top) .logo-container {
  height: 150%;
}

.header .logo-image {
  height: 100%;
  opacity: 1;
}

.hero.hero--top .logo-image {
  opacity: 1;
}

.engagement-box:nth-child(7n + 1) {
  --accent: var(--blue);
  --fg: var(--white);
  --button-color: var(--black);
}
.engagement-box:is(:nth-child(7n + 2), :nth-child(7n + 7)) {
  --accent: var(--green);
}
.engagement-box:nth-child(7n + 3) {
  --accent: var(--ocean);
  --fg: var(--white);
  --button-color: var(--black);
}
.engagement-box:nth-child(7n + 4) {
  --accent: var(--pistachio);
}
.engagement-box:nth-child(7n + 5) {
  --accent: var(--azure);
}
.engagement-box:nth-child(7n + 6) {
  --accent: var(--aqua);
}

.logo-image {
  min-width: 6.25rem;
}

.nav-toggle {
  color: var(--white);
}

.language-nav {
  .nav-link {
    --_bg: var(--blue);
    --_fg: var(--white);
  }
}
