.plugin {
  margin: var(--margin) auto;
  width: 100%;

  @media (--media-md) {
    margin: var(--margin-l) auto;
  }
}

.plugin--centered {
  display: flex;
  & > * {
    margin: auto;
  }
}

.plugin--button {
  text-align: center;
  margin-top: var(--spacing);
}

.accented {
  background-color: var(--accent);
  color: var(--accent-fg);
}

.plugin--wrap {
  padding: var(--padding) 0;

  h1,
  h2,
  h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  @media (--media-md) {
    padding: var(--padding-m) 0;
  }
}

main > .plugin--wrap:last-child {
  margin-bottom: 0;
}

.embedded-video {
  max-width: var(--c10);
}

.responsive-embed {
  aspect-ratio: 16 / 9;
  position: relative;
}

.responsive-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.download__content {
  color: var(--download-color, var(--fg));
  background-color: var(--download-bg, var(--accent));
  padding: var(--p);
}
.download__text {
  margin-bottom: var(--padding);

  > :first-child {
    margin-top: 0;
  }
}

.download__image {
  margin-bottom: var(--padding);
}

.prose .download__button {
  display: flex;
  justify-content: end;
  .button {
    margin-bottom: 0;
  }
}

.is-lonelyButton {
  grid-column: 1 / -1;
}
