main {
  flex: 1 1 100%;
}

/*sites with hero*/
#content-anchor + .container h1 {
  margin-top: var(--margin);

  @media (--media-md) {
    margin-top: var(--margin-m);
  }
}

/*sites without hero*/
main > .container:first-child :is(h1, h2, h3, h4),
.article__lead {
  margin-top: calc(var(--margin) + 4.5rem); /*4.5rem is almost the header height*/

  @media (--media-md) {
    margin-top: calc(var(--margin-m) + 4.5rem);
  }
}

main > *:last-child {
  margin-bottom: var(--margin);

  @media (--media-md) {
    margin-bottom: var(--margin-m);
  }
}

.container {
  max-width: var(--w);
  width: 100%;

  padding: 0 var(--p);

  margin-left: auto;
  margin-right: auto;
}

.container > .container {
  --p: 0;
}

.container {
  &.maxed {
    padding: 0;
    max-width: var(--w-max);
  }
  &.sm--full {
    padding: 0;
    @media (--media-md) {
      padding: 0 var(--p);
    }
  }
  &.md--full {
    padding: 0;
    @media (--media-lg) {
      padding: 0 var(--p);
    }
  }
}
