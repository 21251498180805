.hero-container {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  background-color: var(--hero-bg, var(--accent));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .portrait,
  .landscape {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
  }

  .portrait {
    display: none;

    @media (orientation: portrait) {
      display: block;
    }
  }

  .landscape {
    display: none;

    @media (orientation: landscape) {
      display: block;
    }
  }

  .overflow-arrow {
    --icon-color: var(--white);
    position: absolute;
    bottom: var(--spacing);
    text-align: center;
  }
}

.slogan-container {
  max-width: 100%;
  overflow-x: hidden;
}

.slogan-wrap {
  line-height: 1.5;
}

.slogan {
  position: absolute;
  bottom: min(12rem, 33%);
  width: 100%;

  span {
    background: var(--hero-bg, var(--accent));
    color: var(--hero-color, var(--fg));
    margin-right: auto;
    font-weight: bold;
    font-size: 3rem;
    padding: 0 0.25em;
    word-wrap: break-word; /* Only break words if single words do not fit on a line */
    white-space: preserve-breaks;

    box-decoration-break: clone; /* OMG yea, needs desperately prefixing:  */

    @media (--media-md) {
      font-size: 4rem;
    }
  }
}

html {
  scroll-behavior: smooth;
}

#content-anchor {
  scroll-margin: 6rem;
}
