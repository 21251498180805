figure {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

figcaption {
  width: min-content;
  flex: 1;
}

figure > img {
  flex: 1 0 100%;
}

figure .responsive-embed {
  flex: 1 100%;
}
