@font-face {
  font-family: Afacad;
  font-weight: 400;
  font-style: normal;
  src:
    local("Afacad-Regular"),
    url(../fonts/Afacad/Afacad-Regular.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Afacad;
  font-weight: 700;
  font-style: normal;
  src:
    local("Afacad-Bold"),
    url(../fonts/Afacad/Afacad-Bold.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Afacad;
  font-weight: 400;
  font-style: italic;
  src:
    local("Afacad-Italic"),
    url(../fonts/Afacad/Afacad-Italic.woff2) format("woff2");
  font-display: swap;
}
@font-face {
  font-family: Afacad;
  font-weight: 700;
  font-style: italic;
  src:
    local("Afacad-BoldItalic"),
    url(../fonts/Afacad/Afacad-BoldItalic.woff2) format("woff2");
  font-display: swap;
}
body {
  font-family: Afacad, Helvetica, Arial, sans-serif;
}
