.faq__group {
  margin-top: var(--spacing);
}

.faq__details {
  color: var(--faq-color, var(--fg));
  background-color: var(--faq-bg, var(--accent));
  display: block;
  border-bottom: 1px solid;
  padding-left: 0.5em;
  padding-right: 0.0625em;

  .faq__group + & {
    border-top: 1px solid;
  }
}

h3.faq__question {
  margin: 0 var(--icon-size) 0 0;
  text-align: left;
}

.prose h2.faq__group {
  margin-top: var(--margin);

  @media (--media-md) {
    margin-top: var(--margin-m);
  }
}

.faq__summary {
  padding: 1em 0;
  position: relative;
}

.expand-icon__container {
  --icon-size: 2em;

  position: absolute;
  top: calc((100% - var(--icon-size)) / 2);
  right: 0;

  transition: opacity 0.3s ease-out;
}

details .expand-icon__container {
  & .expand-more {
    visibility: visible;
  }
  & .expand-less {
    visibility: hidden;
  }
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}
details[open] .expand-icon__container {
  & .expand-more {
    visibility: hidden;
  }
  & .expand-less {
    visibility: visible;
  }
  transform: rotate(-180deg);
  transition: transform 0.2s ease;
}
